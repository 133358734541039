<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="10" sm="8" xl="7">
        <v-card class="elevation-16">
          <v-row>
            <v-col>
              <div class="pa-7 pa-sm-12">
                <v-img
                  src="@/assets/images/logo-email-reset.png"
                  max-height="150px"
                  contain
                ></v-img>
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2 mb-4 text-center">
                  Definir nova senha de acesso
                </h2>
                <password
                  class="d-flex justify-center w-100 font-default"
                  v-model="password.password"
                  label="Nova Senha"
                  :cols="12"
                  :sm="10"
                  :md="4"
                />
              </div>
              <div style="display: block ruby; text-align: center;">
                <div style="width: 200px;">
                  <v-btn
                      color="info"
                      class="mr-4 btn-custom"
                      @click="updatePassword"
                      :loading="loading"
                      >Salvar</v-btn
                    >
                </div>
              </div>
              <div style="display: block ruby; text-align: center;">
                <div style="width: 200px;">
                    <v-btn
                        color="second"
                        class="mr-4 mt-4 btn-custom"
                        @click="back"
                        >Voltar ao Login</v-btn>
                </div>
              </div>
              <v-snackbar v-model="snackbar" centered :color="color" elevation="12">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn text v-bind="attrs" @click="snackbar = false">
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Password from '@/components/Password/Password'
export default {
  name: 'FirstAccess',

  data: () => ({
    loading: false,
    snackbar: false,
    message: '',
    color:'',
    password: {},
  }),
  computed: {
  },
  created() {
  },
  components: {
    Password,
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    async updatePassword() {
      this.loading = true
      try {
        let newPassword
        
        //const queryString = window.location.search;
        //const urlParams = new URLSearchParams(queryString);
        //const user = decodeURIComponent(urlParams.get('user'));
        const user = JSON.parse(localStorage.getItem('user'));

        newPassword = {
            new_password: this.password.password,
            confirm_password: this.password.password,
          }

        await this.changePassword({
          passwordInfo: newPassword,
          isManager: true,
          usuario: user.public_id,
        })

        user.is_reset_senha = false;
        localStorage.removeItem("user");
        localStorage.setItem('user', JSON.stringify(user))

        this.loading = false;
        this.color = 'success';
        this.message = 'Senha atualizada com sucesso.';
        this.snackbar = true;
        
        setTimeout( () => this.$router.push({ path: '/' }), 2500);
        
      } catch (error) {

        if (error.response) {
          if (
            error.response.status == 400 &&
            error.response.data.old_password
          ) {
            this.message = 'Senha atual inválida';
          } else if (
            error.response.status == 400 &&
            error.response.data.new_password
          ) {
            this.message = error.response.data.new_password[0];
          }
        } else if (error.message) {
          this.message = error.message;
        } else {
          this.message = error;
        }
        this.color = 'error';
        this.snackbar = true
        this.password = {}
      }
      this.$forceUpdate()
      this.loading = false
    },

    async back() {
      localStorage.removeItem("user");
      this.$router.push({ path: '/auth/login' })
    },
  },
}
</script>

<style>
.password-help-text::before {
  content: '\1F512';
}
.font-default {
  font-size: 12px;
  line-height: 0.5;
}
.btn-custom {
  width: 250px;
}
</style>
